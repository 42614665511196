import { useAuth0 } from '@auth0/auth0-react';
import * as Sentry from '@sentry/nextjs';
import { Analytics } from 'app/lib/analytics';
import type React from 'react';
import { type Fetcher, SWRConfig } from 'swr';

import { useCallback } from 'react';
import { API_BASE_URL } from '../../configuration/constants';

export class FetcherError extends Error {
  readonly rawUrl: string;

  readonly host: string;
  readonly method: string;
  readonly path: string;
  readonly search?: string;

  readonly status: number;
  readonly body: unknown;

  static reRaise(error: FetcherError) {
    return new FetcherError(
      error.method,
      error.status,
      error.rawUrl,
      error.body,
    );
  }

  constructor(method: string, status: number, rawUrl: string, body: unknown) {
    const url = new URL(rawUrl);
    const msg = `API Error fetching ${method} ${url.pathname}, ${status} : ${(body as { message?: string })?.message ?? JSON.stringify(body)}`;

    super(msg);

    this.rawUrl = rawUrl;
    this.host = url.host;
    this.method = method;
    this.path = url.pathname;
    this.search = url.search;
    this.status = status;
    this.body = body;
    this.message = msg;
  }
}

export const useFetchWithAuth = () => {
  const { getAccessTokenSilently, logout } = useAuth0();

  return useCallback(
    async (path: string, req: RequestInit) => {
      let accessToken: string | undefined;
      try {
        accessToken = await getAccessTokenSilently().catch();
      } catch {
        // Couldn't get access token for any reason, force user to re-login.
        await logout();
      }

      if (!accessToken) return;

      const url = `${API_BASE_URL}${path}`;

      const res = await fetch(url, {
        ...req,
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          Authorization: `Bearer ${accessToken}`,
          ...req.headers,
        },
      });

      if (!res.ok) {
        throw new FetcherError(
          req.method ?? 'GET',
          res.status,
          url,
          await res.json(),
        );
      }

      return res.json();
    },
    [getAccessTokenSilently, logout],
  );
};

export const SWRContext = (props: { children?: React.ReactNode }) => {
  const fetchWithAuth = useFetchWithAuth();

  const fetcher: Fetcher = (resource: string) => {
    return fetchWithAuth(resource, {
      method: 'GET',
    });
  };

  return (
    <SWRConfig
      value={{
        fetcher,
        onError: (error: FetcherError | TypeError | Error) => {
          if (
            error instanceof TypeError &&
            (error.message === 'Failed to fetch' ||
              error.message ===
                'NetworkError when attempting to fetch resource.')
          ) {
            // Network error, SWR will auto-retry, and we monitor API downtime separately
            return;
          }

          if (error instanceof FetcherError) {
            // API fetching errors will always go through this path and have the same stack trace.
            // Make sure to group them by what the API error actually is, rather than the stack trace.
            Sentry.withScope((scope) => {
              // Docs:
              // https://docs.sentry.io/platforms/javascript/usage/sdk-fingerprinting
              scope.setFingerprint([
                error.method,
                error.path,
                String(error.status),
              ]);

              Sentry.captureException(error, {
                data: {
                  host: error.host,
                  method: error.method,
                  path: error.path,
                  search: error.search,
                  status: error.status,
                  body: error.body,
                },
              });
            });

            if (error.status === 401 || error.status === 403) {
              // Unauthorized or Forbidden, force user to re-login after logging the error
              window.open('/logout', '_self');
            }

            if (error.status === 404) {
              // 404s should be handled by the component
              // Track them on posthog for volume tracking too
              Analytics.capture('404_not_found', {
                url: error.path,
              });
              return;
            }

            return;
          }
          console.error(error);
          Sentry.captureException(error);
        },
      }}
    >
      {props.children}
    </SWRConfig>
  );
};
