import Icon, { type IconType } from '@carbonfact/ui-components/src/Icon';
import { Button } from 'app/components/Button';
import classNames from 'classnames';

type ButtonIconProps = {
  disabled?: boolean;
  onClick: () => void;
  tooltip?: string;
  icon: IconType;
  className?: string;
  variant?: 'primary' | 'secondary' | 'invisible';
  height?: string;
  width?: string;
};
export const ButtonIcon = ({
  disabled,
  tooltip,
  onClick,
  className,
  icon,
  variant = 'secondary',
  height = 'h-8',
  width = 'w-8',
}: ButtonIconProps) => (
  <Button.Default
    disabled={disabled}
    className={classNames(height, width, className)}
    tooltip={tooltip}
    variant={variant}
    onClick={onClick}
  >
    <Icon className="min-w-[20px]" width={20} height={20} icon={icon} />
  </Button.Default>
);
